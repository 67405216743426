import React, { useContext, Fragment } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useStyletron } from 'baseui'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

// Components
import Heading from '@global/Heading'
import Notification from '@global/Notification'
import Standard from '@components/Card/Standard'

const Masonry = ({ horses }) => {
  const [css, theme] = useStyletron()

  return (
    <React.Fragment>
      <div>
        <Heading>Featured horses</Heading>
      </div>

      <FlexGrid flexGridColumnCount={[1, 1, 2, 3]} flexGridColumnGap="scale200" flexGridRowGap="scale200">
        {isEmpty(horses) ? (
          <FlexGridItem>
            <Notification full>We don&apos;t have any featured horses at present.</Notification>
          </FlexGridItem>
        ) : (
          <React.Fragment>
            {horses.map((horse) => (
              <FlexGridItem key={horse.id}>
                <Standard id={horse.id} />
              </FlexGridItem>
            ))}
          </React.Fragment>
        )}
      </FlexGrid>
    </React.Fragment>
  )
}

export default Masonry
