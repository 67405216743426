import React from 'react'

import Explore from './Explore'
import Hero from './Hero'
import Masonry from './Masonry'

const Home = ({ horses }) => (
  <>
    <Explore />
    <Masonry horses={horses} />
    <Hero />
  </>
)

export default Home
