// Packages
import React from 'react'
import PropTypes from 'prop-types'
import prisma from '@src/lib/client'

// Components
import Home from '@components/Home/Home'

// Layout
import Layout from '@components/Layout'

const IndexPage = ({ horses }) => (
  <Layout>
    <Home horses={horses} />
  </Layout>
)

export const getStaticProps = async () => {
  const horses = await prisma.horse.findMany({
    where: {
      status: 'AVAILABLE'
    },
    take: 9,
    orderBy: { createdAt: 'desc' },
    select: {
      id: true
    }
  })

  return {
    props: { horses },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 60 // In seconds
  }
}

IndexPage.propTypes = {
  horses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  )
}

IndexPage.defaultProps = {
  horses: []
}

export default IndexPage
