import React from 'react'
import Link from 'next/link'
import { useStyletron } from 'baseui'
import { Card, StyledTitle } from 'baseui/card'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import Heading from '@global/Heading'

const Explore = () => {
  const [css, theme] = useStyletron()

  return (
    <>
      <div>
        <Heading>Explore</Heading>
      </div>

      <FlexGrid flexGridColumnCount={[1, 1, 2, 3]} flexGridColumnGap="scale200" flexGridRowGap="scale200">
        <FlexGridItem>
          <ExploreCard
            imageUrl="https://res.cloudinary.com/asciant/image/upload/q_auto:low,f_auto,w_575/v1/sift2fhrgxkougs18sgm"
            imageAlt="Horses running in a field"
            label="Breeds"
            url="/explore/breeds"
          />
        </FlexGridItem>

        <FlexGridItem>
          <ExploreCard
            imageUrl="https://res.cloudinary.com/asciant/image/upload/q_auto:low,f_auto,w_575/v1/ocurycmezvmhiuavxwmq"
            imageAlt="Horse jumping at a competition"
            label="Disciplines"
            url="/explore/disciplines"
          />
        </FlexGridItem>

        <FlexGridItem>
          <ExploreCard
            imageUrl="https://res.cloudinary.com/asciant/image/upload/q_auto:low,f_auto,w_575/v1/eiyg6kkas9gddginml5r"
            imageAlt="Young girl with her pet horse"
            label="Rider Level"
            url="/explore/level"
          />
        </FlexGridItem>
      </FlexGrid>
    </>
  )
}

const ExploreCard = ({ imageUrl, label, url }) => {
  const [css, theme] = useStyletron()

  return (
    <Link href={url}>
      <a>
        <Card
          overrides={{
            Contents: {
              style: ({ $theme }) => ({
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                marginBottom: 0,
                ...$theme.borders.border300,
                borderRadius: $theme.borders.radius300,
                overflow: 'hidden',
                boxShadow: theme.lighting.shadow400
              })
            }
          }}
        >
          <div
            className={css({
              display: 'block',
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '100%',
              objectFit: 'cover',
              height: 0,
              paddingTop: '56.25%', // 16:9
              position: 'relative'
            })}
          />
          <StyledTitle>
            <div
              className={css({
                paddingLeft: theme.sizing.scale600,
                paddingTop: theme.sizing.scale400
              })}
            >
              {label}
            </div>
          </StyledTitle>
        </Card>
      </a>
    </Link>
  )
}

export default Explore
