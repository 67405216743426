import * as React from 'react'
import PropTypes from 'prop-types'
import { Notification } from 'baseui/notification'

const Notify = ({ text, type, full, closeable, children }) => {
  switch (type) {
    case 'negative': {
      return (
        <Notification
          closeable={closeable}
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                width: full ? 'auto' : '288px',
                outline: `none`,
                borderTopLeftRadius: $theme.sizing.scale0,
                borderTopRightRadius: $theme.sizing.scale0,
                borderBottomLeftRadius: $theme.sizing.scale0,
                borderBottomRightRadius: $theme.sizing.scale0,
                transitionProperty: 'all',
                transitionDuration: $theme.animation.timing200,
                transitionTimingFunction: $theme.animation.easeInOutCurve
              })
            }
          }}
          kind={type}
        >
          {text || children}
        </Notification>
      )
    }
    case 'positive': {
      return (
        <Notification
          closeable={closeable}
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                width: full ? 'auto' : '288px',
                outline: `none`,
                borderTopLeftRadius: $theme.sizing.scale0,
                borderTopRightRadius: $theme.sizing.scale0,
                borderBottomLeftRadius: $theme.sizing.scale0,
                borderBottomRightRadius: $theme.sizing.scale0,
                transitionProperty: 'all',
                transitionDuration: $theme.animation.timing200,
                transitionTimingFunction: $theme.animation.easeInOutCurve
              })
            }
          }}
          kind={type}
        >
          {text || children}
        </Notification>
      )
    }
    case 'warning': {
      return (
        <Notification
          closeable={closeable}
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                width: full ? 'auto' : '288px',
                outline: `none`,
                borderTopLeftRadius: $theme.sizing.scale0,
                borderTopRightRadius: $theme.sizing.scale0,
                borderBottomLeftRadius: $theme.sizing.scale0,
                borderBottomRightRadius: $theme.sizing.scale0,
                backgroundColor: $theme.colors.warning100,
                color: $theme.colors.warning600,
                transitionProperty: 'all',
                transitionDuration: $theme.animation.timing200,
                transitionTimingFunction: $theme.animation.easeInOutCurve
              })
            }
          }}
          kind={type}
        >
          {text || children}
        </Notification>
      )
    }
    default: {
      return (
        <Notification
          closeable={closeable}
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                width: full ? 'auto' : '288px',
                outline: `none`,
                borderTopLeftRadius: $theme.sizing.scale0,
                borderTopRightRadius: $theme.sizing.scale0,
                borderBottomLeftRadius: $theme.sizing.scale0,
                borderBottomRightRadius: $theme.sizing.scale0,
                transitionProperty: 'all',
                transitionDuration: $theme.animation.timing200,
                transitionTimingFunction: $theme.animation.easeInOutCurve
              })
            }
          }}
          kind="info"
        >
          {text || children}
        </Notification>
      )
    }
  }
}

Notify.propTypes = {
  text: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  type: PropTypes.oneOf(['info', 'positive', 'negative', 'warning']),
  full: PropTypes.bool,
  closeable: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
}

Notify.defaultProps = {
  type: 'info',
  full: false,
  closeable: false
}

export default Notify
