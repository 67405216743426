import React, { Fragment } from 'react'
import Link from 'next/link'
import { Button, KIND } from 'baseui/button'

import { useStyletron } from 'baseui'

import Heading from '@global/Heading'

const Hero = () => {
  const [css, theme] = useStyletron()

  return (
    <>
      <div>
        <Heading>Selling with us</Heading>
      </div>

      <div>
        <div
          className={css({
            position: 'relative',
            overflow: 'hidden',
            borderRadius: theme.borders.radius300,
            boxShadow: theme.lighting.shadow400,
            maxHeight: '325px'
          })}
        >
          <div
            className={css({
              display: 'block',
              backgroundImage: `url(https://res.cloudinary.com/asciant/image/upload/q_auto:low,f_auto,w_1250/v1/p1xwg0gxahq6egezzgzd)`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '100%',
              objectFit: 'cover',
              height: 0,
              paddingTop: '56.25%', // 16:9
              position: 'relative'
            })}
          />

          <div
            className={css({
              background: 'linear-gradient(to right, black, transparent)',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              minHeight: '250px',
              [theme.mediaQuery.small]: {
                height: '325px'
              }
            })}
          >
            <div
              className={css({
                padding: '25px',
                color: '#fff',
                width: '370px',
                [theme.mediaQuery.small]: {
                  padding: '15px',
                  width: '285px'
                }
              })}
            >
              <p>Selling your horse has never been easier.</p>
              <div
                className={css({
                  display: 'block',
                  [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
                    display: 'none'
                  }
                })}
              >
                <p>We know how difficult selling a horse can be, this site was made to simplify it all.</p>
              </div>
              <Link href="/learn" passHref>
                <Button
                  kind={KIND.secondary}
                  className={css({
                    backgroundColor: '#fff',
                    textTransform: 'capitalize',
                    borderRadius: theme.borders.radius200
                  })}
                >
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero
